import './edituser.scss'
import React, { useEffect, useState } from 'react'
import Sidebar from '../sidebar/Sidebar'
import Navbar from '../navbar/Navbar'
import { FormControl, MenuItem, Select, TextField } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { editUser } from './UserApi'
import {listSms} from '../sms/SmsApi'
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Edituser = (props) => {
    const { state } = useLocation();
    console.log(state)

    var newobj = [];

    for (let x = 0; x < state.data.sender_id.length; x++) {
        console.log(state.data.sender_id[x].name);
        newobj.push(state.data.sender_id[x].name);
    }

  var temobj = [];

  for (let x = 0; x < state.data.template.length; x++) {
    console.log(state.data.template[x].name);
    temobj.push(state.data.template[x].name);
}




    let navigate = useNavigate();
    const { id } = useParams();
    const [data, setData] = useState([])


    const [username, setUsername] = useState(state.data.username)
    const [password, setPassword] = useState(state.data.password)
    const [provider, setProvider] = useState(state.data.providername)
    const [sender, setSender] = useState(newobj.toString())
    const [appname, setAppname] = useState(state.data.appname)
    const [product, setProduct] = useState([]);
    const [template,setTemplate] = useState(temobj.toString())

           
    const hadelProvider =(e)=>{
        let {value} = e.target
        setProvider(value)
     }


    const onSubmit = async () => {
        var temobj = [];
        var template_idarray = template.split(',');
        for (let x = 0; x < template_idarray.length; x++){
        var objects = {
        name:template_idarray[x] 
        };
        temobj.push(objects);
       }

        var newobj = [];
        var sender_idarray = sender.split(',');
        for (let x = 0; x < sender_idarray.length; x++) {
            var objects = {
                name: sender_idarray[x]
            };
            newobj.push(objects);
        }

        var data = JSON.stringify({
            "username": username,
            "password": password,
            "sender_id": newobj,
            "provider": provider,
            "appname": appname,
            "id": state.data.id,
            "template":temobj
        });

        editUser(data).then(res=>{
            console.log("edit",res)
            if(res.status===true){           
                toast.success('User Updated Successfully', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                    setData(res)
                    setTimeout(() => {
                        
                        navigate('/users',{replace:true})
                    }, 2000);
            }
          })

    }

    useEffect(() => {
        setData()
        displaysmsprovider()

    }, [])

    const displaysmsprovider = () => {
        var data = JSON.stringify({
            "limit": "10",
            "öffset": "0"
        });
        listSms(data)
            .then( response =>{
                setProduct(response.smsproviders.users)
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const OnsubmitForm = (event) => {
        event.preventDefault();
    }
    return (
        <div className="edit">
            <Sidebar />
            <div className="editContainer">
                <Navbar />
                <div className="editHeading">
                    <h1>EDIT USER</h1>
               </div>
                <div className="bottom">
                    <div className="right">

                        <form onSubmit={OnsubmitForm}>
                            <div className="formInput">

                                <div className='formInput' style={{ marginTop: '3px' }}>

                                    <FormControl variant="standard" sx={{ m: 1, minWidth: 420 }}>

                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            onChange={hadelProvider}
                                            value={provider}
                                            label={provider}
                                    
                                        > 
                                            {product.map(function (item, id) {
                                            
                                                return (
                                                    <MenuItem selected={item.providername===state.data.provider?true : false}
                                                        value={item.id}
                                                        defaultValue={provider}
                                                        label={provider}
                                                        >
                                                     {item.providername}
                                                        </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="formInput">
                                <TextField
                                    onChange={(e) => setUsername(e.target.value)}
                                    id="standard-basic"
                                    label="UserName"
                                    variant="standard"
                                    value={username}
                                    fullWidth sx={{ m: 1 }}
                                />
                            </div>
                            <div className="formInput">
                                <TextField
                                    id="standard-basic"
                                    label="Password"
                                    variant="standard"
                                    placeholder="password"
                                    fullWidth sx={{ m: 1 }}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div className="formInput">
                            <label>Sender ID</label>
                                <TextareaAutosize
                                    id="standard-basic"
                                    label="Sender_ID"
                                    variant="standard"
                                    placeholder="sender_id"
                                    fullWidth sx={{ m: 1 }}
                                    value={sender}
                                    onChange={(e) => setSender(e.target.value)}

                                    aria-label="minimum height"
                                    minRows={2}
                                    style={{ width: 425 }}

                               />
                               <small style={{marginLeft:'10px'}}>For Multiple Value Use Comma ( , )</small>

                            </div>
                            <div className="formInput">
                                <TextField
                                    id="standard-basic"
                                    label="Appname"
                                    variant="standard"
                                    placeholder="appname"
                                    fullWidth sx={{ m: 1 }}
                                    value={appname}
                                    onChange={(e) => setAppname(e.target.value)}
                               />
                            </div>
                               
                            <div className="formInput">
                            <label> Template</label>
                                <TextareaAutosize
                                    id="standard-basic"
                                    label="Template"
                                    variant="standard"
                                    fullWidth sx={{ m: 1 }}
                                    value={template}
                                    onChange={(e) => setTemplate(e.target.value)}


                                    aria-label="minimum height"
                                    minRows={2}
                                    style={{ width: 425 }}
                               />
                               <small style={{marginLeft:'10px'}}>For Multiple Value Use Comma ( , )</small>

                            </div>
                            

                            <div className="formInput">
                                <button style={{ marginLeft: '8rem' }} onClick={() => onSubmit()}>Update</button>
                                <ToastContainer />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Edituser



