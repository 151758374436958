import './userlist.scss'
import { Button, Stack } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Navbar from '../navbar/Navbar'
import Sidebar from '../sidebar/Sidebar'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Pagination, Tooltip } from "@mui/material";
import Loading from '../loading/Loading'
import VisibilityIcon from '@mui/icons-material/Visibility';
import UserDeleteModal from '../action/usermodal/UserDeleteModal'
import UserDetailsModal from '../action/usermodal/UserDetailsModal'
import { listUser,deleteUser } from './UserApi'

const UsersList = (props) => {

  const navigate = useNavigate()
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false)
  const [page,setPage] = useState(1);
  const [totalData, setTotalData] = useState(null)
  const [modal, openModal] = useState(false)
  const [selectedata, setselecteddata] = useState('');
  const [newmodal, setNewmodal] = useState(false)


  useEffect(() => {
    displaysmsprovider();
  }, [page])

  const displaysmsprovider = () => {
    let data = {
      limit :4,
      offset:page==1?0:(page-1)*4
      
    }
   
    listUser(data).then(res=>{
      console.log("first",res)
      let  {users : {usersCount,users = []}} = res || {}
      setLoading(true)
      setTotalData(usersCount)
      setUser(users)
    })
  }
  const deleteOperation = (id) => {
    var data = {id:id};
    deleteUser(data).then(function (response) {
        if (response.status == true) {        
          displaysmsprovider()
        } else {           
        }
      })
      .catch(function (error) {
        setUser()
      });
  }

  const handleChange =(event, value)=>{
    setPage(value);
   }
   const closemodal = () => {
    openModal(false)
  }
  const closeModal = () =>{
    setNewmodal(false)
  }

  const refreshPage = value =>{
    if(value?.data?.status){
      displaysmsprovider()
      closemodal()
    }
  }
  

  return (
    <div className="user">
      <Sidebar />
      <UserDeleteModal 
        visility={modal} 
        selectdata={selectedata} 
        closemodal={event => closemodal(event)} 
        deleteOperation={()=>deleteOperation} 
        displaysmsprovider={()=>displaysmsprovider}
        onUpdate={value => refreshPage(value)}  
      />
      <UserDetailsModal 
      visility={newmodal} 
      data={selectedata} 
      closeModal={event =>closeModal(event)} 
      />
      <div className="userContainer">
        <Navbar />
        <div className="top" style={{ backgroundColor: '#294e9f'}}>
          <h2>USERS</h2>
          <Link to='/adduser' style={{ textDecoration: 'none' }}>
            <Button variant="outlined" startIcon={<AddCircleOutlineIcon />} style={{ backgroundColor: 'aliceblue', color: 'black' }}>Add User</Button>
          </Link>
        </div>
        <div className='bottom'>
          <div className='right'>
          <TableContainer component={Paper} className="table" style={{width:'100vw',objectFit: 'cover' }}
            sx={{
              height: 400
            }}
          >
            <div className='userTable'>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" style={{ fontSize: '10px' }}>
              <TableHead style={{ fontweight: 'bold' }}>
                <TableRow >
                  <TableCell className="tableCell" style={{ fontWeight: 'bold', fontSize: '12px' }}>Username</TableCell>
                  <TableCell className="tableCell" style={{ fontWeight: 'bold', fontSize: '12px' }}>Password</TableCell>
                  <TableCell className="tableCell" style={{ fontWeight: 'bold', fontSize: '12px' }}>App Name</TableCell>
                  <TableCell className="tableCell" style={{ fontWeight: 'bold', fontSize: '12px' }}>Provider</TableCell>
                  <TableCell className="tableCell" style={{ fontWeight: 'bold', fontSize: '12px' }}>Action</TableCell>
                  <TableCell className="tableCell" style={{ fontWeight: 'bold', fontSize: '12px' }}>View</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? user.map(function (item, id) {
                 return (
                    <TableRow key={item.id} style={{height:'3rem'}} >
                      <TableCell className="tableCell">{item.username}</TableCell>
                      <TableCell className="tableCell">{item.password}</TableCell>
                      <TableCell className="tableCell">{item.appname}</TableCell>
                      <TableCell className="tableCell">{item.provider}</TableCell>
                      <TableCell className='tableCell' >
                      <span class='bi bi-pencil-fill' style={{fontSize:'15px',marginLeft:'-3px',color:'gray'}} onClick={() => navigate(`/edituser/${item.id}`, { state: { data: item } })}></span>
                      <Tooltip title='Delete'>                          
                        <span className="bi bi-trash-fill" style={{fontSize:'17px',marginLeft:'10px',color:'red'}} onClick={()=>{openModal(true);setselecteddata(item.id);  }}></span>
                      </Tooltip>
                      </TableCell>
                      <TableCell className='tableCell'>
                        <VisibilityIcon color='string' style={{ cursor: "pointer" }} onClick={() => { setNewmodal(true); setselecteddata(item);}}/>
                      </TableCell>
                    </TableRow>                                              
                  )
                }) :
                  <Loading />
                }
              </TableBody>
            </Table>
            </div>
           
            <div className='pagination'>
              <Stack spacing={1}>
                <Pagination count={Math.ceil(totalData/4)} page={page} onChange={handleChange} variant="outlined" shape="rounded" />
              </Stack>         
            </div>
          </TableContainer>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UsersList