import React from 'react';
import './editsms.scss';
import Sidebar from '../sidebar/Sidebar';
import Navbar from '../navbar/Navbar';
import { TextField, Select } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { editSms } from './SmsApi';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const EditSms = (props) => {
    const { state } = useLocation();
    var newobj = [];
    for (let x = 0; x < state.data.sender_id.length; x++) {
        console.log(state.data.sender_id[x].name);
        newobj.push(state.data.sender_id[x].name);
    }

    var tempobj = [];
    for (let x = 0; x < state.data.template.length; x++) {
        console.log(state.data.template[x].name);
        tempobj.push(state.data.template[x].name);
    }

    
    let navigate = useNavigate();
    const { id } = useParams();
    const [data, setData] = useState([])

    const [username, setUsername] = useState(state.data.username)
    const [password, setPassword] = useState(state.data.password)
    const [apikey, setApikey] = useState(state.data.api_key)
    const [apiurl, setApiurl] = useState(state.data.api_url)
    const [provider, setProvider] = useState(state.data.providername)
    const [sender, setSender] = useState(newobj.toString())
    const [providericon, setProvidericon] = useState(state.data.providericon)
    const [template, setTemplate] = useState(tempobj.toString())
    
    const hadelProvider =(e)=>{
       let {value} = e.target
       setProvider(value)
    }
    const onSubmit = async () => {
        var newobj = [];
        var sender_idarray = sender.split(',');
        for (let x = 0; x < sender_idarray.length; x++) {
            var objects = {
                name: sender_idarray[x]
            };
            newobj.push(objects);
        }

        var tempobj = [];
        var template_array = template.split(',');
        for (let x = 0; x < template_array.length; x++) {
            var objects = {
                name: template_array[x]
            };
            tempobj.push(objects);
        }

        var data = JSON.stringify({
            "username": username,
            "password": password,
            "api_key": apikey,
            "api_url": apiurl,
            "sender_id": newobj,
            "providername": provider,
            "providericon": providericon,
            "id": state.data.id,
            "template":tempobj
        });

        editSms(data).then(res=>{
            console.log("first",res)
            if(res.status==true){
                toast.success('Sms Provider Edited Successfully', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                    setTimeout(() => {                       
                        navigate('/sms',{replace:true})
                    }, 2000);
            }
          })
    }

    useEffect(() => {
        setData()
    }, [])
    const OnsubmitForm = (event) => {
        event.preventDefault();
    }
    return (
        <div className="edit">
            <Sidebar />
            <div className="editContainer">
                <Navbar />
                <div className="editHeading">
                    <h1>EDIT SMS PROVIDER</h1>
               </div>
                <div className="bottom">
                    <div className="right">
                        <form onSubmit={OnsubmitForm}>
                            <div className="formInput">
                                <div className='formInput' style={{ marginTop: '3px' }}>
                                    <FormControl variant="standard" sx={{ m: 1, minWidth: 420 }}>
                                        <InputLabel id="demo-simple-select-standard-label">Provider Name</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={provider}
                                            defaultValue={provider}
                                            label={provider}
                                           onChange={hadelProvider}                                            
                                        >

                                            <MenuItem  value='2Factor'>2Factor</MenuItem>
                                            <MenuItem  value='Smscountry'>Smscountry</MenuItem>
                                            <MenuItem  value='msg91'>msg91</MenuItem>
                                            <MenuItem  value='textlocal'>textlocal</MenuItem>
                                           

                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="formInput">
                                <TextField onChange={(e) => setUsername(e.target.value)}
                                    id="standard-basic"
                                    label="UserName"
                                    variant="standard"
                                    value={username}
                                    fullWidth sx={{ m: 1 }}
                                />
                            </div>
                            <div className="formInput">
                                <TextField
                                    id="standard-basic"
                                    label="Password"
                                    variant="standard"
                                    placeholder="password"
                                    fullWidth sx={{ m: 1 }}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}

                                />
                            </div>
                           <div className="formInput">
                                <TextField
                                    id="standard-basic"
                                    label="Api_KEY"
                                    variant="standard"
                                    placeholder="api_key"
                                    fullWidth sx={{ m: 1 }}
                                    value={apikey}
                                    onChange={(e) => setApikey(e.target.value)}
                                />
                            </div>
                            <div className="formInput">
                                <TextField
                                    id="standard-basic"
                                    label="Api_URL"
                                    variant="standard"
                                    placeholder="api_url"
                                    fullWidth sx={{ m: 1 }}
                                    value={apiurl}
                                    onChange={(e) => setApiurl(e.target.value)}
                                />
                            </div>
                            <div className="formInput">
                            <label>Sender ID</label>
                                <TextareaAutosize
                                    id="standard-basic"
                                    label="Sender_ID"
                                    variant="standard"
                                    fullWidth sx={{ m: 1 }}
                                    value={sender}
                                    onChange={(e) => setSender(e.target.value)}


                                    aria-label="minimum height"
                                    minRows={2}
                                    style={{ width: 425 }}
                               />
                               <small>For Multiple Value Use Comma ( , )</small>
                            </div>
                            <div className="formInput">
                                <TextField
                                    id="standard-basic"
                                    label="Providericon"
                                    variant="standard"
                                    placeholder="providericon"
                                    fullWidth sx={{ m: 1 }}
                                    value={providericon}
                                    onChange={(e) => setProvidericon(e.target.value)}
                                />
                            </div>

                            <div className="formInput">
                                <label>Template</label>
                                <TextareaAutosize
                                    id="standard-basic"
                                    label="Template"
                                    variant="standard"
                                    fullWidth sx={{ m: 1 }}
                                    value={template}
                                    onChange={(e) => setTemplate(e.target.value)}

                                    aria-label="minimum height"
                                    minRows={2}
                                    style={{ width: 425 }}


                                />
                               <small>For Multiple Value Use Comma ( , )</small>

                            </div>


                            <div className="formInput">
                                <button onClick={() => onSubmit()} style={{ marginLeft: '8rem' }}>Update</button>
                                <ToastContainer />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default EditSms