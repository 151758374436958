import React from 'react'
import ReactLoading from 'react-loading'
const Loading = () => {
  return (
    <div  style={{position:'relative',left:'450px',marginTop:'50%'}}>
        <ReactLoading
        type="spinningBubbles"
        color="#0000FF"
        height={200}
        width={50}
      />    
    </div>
    
  )
}

export default Loading