import './Smsdetails.scss'
import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Divider } from '@mui/material';



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: -2,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon onClick={props.closeModal}/>
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};
export default function SmsDetailsModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
 
   
  return (
    <div className='popupDiv'>
      <BootstrapDialog
        onClose={props.closeModal}
        aria-labelledby="customized-dialog-title"
        open={props.visility} 

      >
        <Divider />
        <BootstrapDialogTitle id="customized-dialog-title"
          onClose={props.closeModal}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
        </BootstrapDialogTitle>
        <Divider />
        <div className='flex-container'>
       
       
            <div className='flex-item' >
             <h6 className='head'>Sender ID :</h6>
               
               <span className='spanText' >
               {props && props.data.sender_id ?
                    props.data.sender_id.map((senderID) =>
                    (
                       <span>
                         {senderID.name},
                         </span>
                                           
                    ))
                    : null}
               </span>                  
            </div>
            
            <div className='flex-item' >
             <h6 className='head'>Template : </h6>
               <span className='spanText2'>
               {props && props.data.template ?
                    props.data.template.map((template) =>
                    (
                       <span>
                         {template.name},
                       </span>                    
                    ))
                    : null}
               </span>
            
            </div>
        </div>

        

      </BootstrapDialog>
    </div>
  );
}
