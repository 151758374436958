import './addsms.scss'
import React from 'react'
import Sidebar from '../sidebar/Sidebar'
import Navbar from '../navbar/Navbar'
import { TextField } from '@mui/material'
import { useState } from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useNavigate } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import {addSms} from './SmsApi'
import {ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const AddSms = () => {

  
  const navigate = useNavigate();

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [apikey, setApikey] = useState('')
  const [apiurl, setApiurl] = useState('')
  const [providername, setProvidername] = useState('')
  const [sender, setSender] = useState([])
  const [providericon, setProvidericon] = useState('')
  const [template, setTemplate] = useState([])
  
  const OnsubmitForm = (event) =>{
          event.preventDefault(); 
  }

  const handelSubmit = (id) => {
  
    var newobj=[];
    var sender_idarray = sender.split(',');
    for (let x = 0; x <sender_idarray.length; x++) {
      var objects = {
        name:sender_idarray[x] 
      };
      newobj.push(objects);
    }


    var temobj =[];
    var template_idarray = template.split(',');
    for (let x = 0; x <template_idarray.length; x++){
    var objects = {
    name:template_idarray[x] 
    };
    temobj.push(objects);
   }

    var data = JSON.stringify({
      "username": username,
      "password": password,
      "sender_id": newobj,
      "providername": providername,
      'api_key':apikey,
      'api_url':apiurl,
      'providericon':providericon,
      'template':temobj
    });

    addSms(data).then(res=>{
      console.log("first",res)
      if(res.status==true){
        toast.success('Provider Added Successfully', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          setTimeout(() => {                       
            navigate('/sms')
          }, 2000);
       
      }
    })
     
  }
 
  return (
    <div className="sms">
      <Sidebar />
      <div className="smsContainer">
        <Navbar />
        <div className="heading">
          <h1>ADD SMS PROVIDER</h1>
        </div>
        <div className="bottom">
          <div className="right">

            <form onSubmit={OnsubmitForm}>
              <div className='formInput' style={{ marginTop: '3px' }}>

                <FormControl variant="standard" sx={{ m: 1, minWidth: 420 }}>
                  <InputLabel id="demo-simple-select-standard-label">Provider Name</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={providername}
                    onChange={(e) => setProvidername(e.target.value)}
                    label="Provider Name"
                    name='dropdown'
                  >
                    <MenuItem value='2Factor'>2Factor</MenuItem>
                    <MenuItem value='Smscountry'>Smscountry</MenuItem>
                    <MenuItem value='msg91'>msg91</MenuItem>
                    <MenuItem value='textlocal'>textlocal</MenuItem>

                  </Select>
                </FormControl>
              </div>


              <div className="formInput">
                <TextField id="standard-basic" label="UserName" variant="standard" placeholder="username" name='username' fullWidth sx={{ m: 1 }} onChange={(e) => setUsername(e.target.value)} />
              
              </div>


              <div className="formInput">
                <TextField id="standard-basic" label="Password" variant="standard" placeholder="password" name='password' fullWidth sx={{ m: 1 }} onChange={(e) => setPassword(e.target.value)} />
              
              </div>

              <div className="formInput">
                <TextField id="standard-basic" label="Api_KEY" variant="standard" placeholder="api_key" name='api_key' fullWidth sx={{ m: 1 }} onChange={(e) => setApikey(e.target.value)} />
               
              </div>
              <div className="formInput">
                <TextField id="standard-basic" label="Api_URL" variant="standard" placeholder="api_url" name='api_url' fullWidth sx={{ m: 1 }} onChange={(e) => setApiurl(e.target.value)} />
                
              </div>
              <div className="formInput">
              <label>Sender ID</label>
                <TextareaAutosize id="standard-basic" label="Sender_ID" variant="standard" name='sender_id' fullWidth sx={{ m: 1 }} onChange={(e) => setSender(e.target.value)} 
                
                aria-label="minimum height"
                minRows={2}
                style={{ width: 425 }}
                />
                 <small style={{marginLeft:'10px'}}>For Multiple value use comma ( , )</small>
              </div>
              <div className="formInput">
                <TextField id="standard-basic" label="Provider_Icon" variant="standard" placeholder="providericon" name='providericon' fullWidth sx={{ m: 1 }} onChange={(e) => setProvidericon(e.target.value)} />
              </div>

              <div className="formInput">
                <label>Template</label>
                <TextareaAutosize id="standard-basic"  variant="standard"  name='providericon' shrink='true' fullWidth sx={{ m: 1 }} onChange={(e) => setTemplate(e.target.value)}
                label="Template"
                aria-label="minimum height"
                minRows={2}
                style={{ width: 425 }}
                InputLabelProps={{ shrink: true }}

                />
                <small style={{marginLeft:'10px'}}>For Multiple value use comma ( , )</small>

              </div>

              <div className="formInput">
                <button style={{ marginLeft: '8rem' }} onClick={() => handelSubmit()}>Submit</button>
                <ToastContainer />
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>

  )
}

export default AddSms