import {baseUrl} from '../constants/BaseUrl'
import axios from "axios";

let access_token = localStorage.getItem('token');


export const reportsData = async (payload) => {
    try {
        const {data} = await axios.post(`${baseUrl}/message/listmessage`,payload,{
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json'
             },
        })
        return data
    } catch (error) {
        Promise.reject()
    }
}