import React, { useState, useEffect } from 'react'
import './report.scss'
import Navbar from '../navbar/Navbar'
import Sidebar from '../sidebar/Sidebar'
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Pagination,Stack } from "@mui/material";
import Loading from '../loading/Loading'
import VisibilityIcon from '@mui/icons-material/Visibility';
import Modal from '../action/reportsmodal/ReportsModal'
import {reportsData} from './ReportApi'


const UsersList = () => {

 
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, openModal] = useState(false);
  const [selectedata, setselecteddata] = useState([]);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(null)
 

  useEffect(() => {
    displaysmsprovider();
  }, [page])
  
  const displaysmsprovider = () => {
    let data = {
      limit: 4,
      offset:page==1?0:(page-1)*4
    }
    reportsData(data).then(res => {
      let  { sendmessage: { sendmessagecount, sendmessage = [] }} = res || {}
      setLoading(true)
      setTotalData(sendmessagecount)
      setUser(sendmessage)
    })
  }
  const closemodal = () => {
    openModal(false)
  }
  const statusdata = (status) => {
    if (status == 'Sent') {
      return (
        <p style={{ color: 'grey', fontWeight: 'bold' }}>Sent</p>
      )
    }
    if (status == 'DELIVERED') {
      return (
        <p style={{ color: 'green', fontWeight: 'bold' }}>Delivered</p>
      )
    }
    if (status == 'Failed' || status == 'REJECTD') {
      return (
        <p style={{ color: 'red', fontWeight: 'bold' }}>{status}</p>
      )
    }
  }
  const handleChange = (event, value) => {
    setPage(value);
  }
  return (
    <div className="report">
      <Sidebar />
      <Modal visility={modal} data={selectedata} closemodal={event => closemodal(event)} />
      
      <div className="reportContainer">
        <Navbar />
        <div className="top" style={{ backgroundColor: '#294e9f' }}>
          <h2>REPORTS</h2>
        </div>
        <div className='bottom'>
          

          <TableContainer component={Paper} className="table" 
            sx={{
              height: 300
            }}>

              <div style={{height:'330px'}}>
            <Table sx={{ minWidth: 850 }} aria-label="simple table" style={{ fontSize: '10px' }}>
              <TableHead style={{ fontweight: 'bold' }}>
                <TableRow >
                  <TableCell className="tableCell" style={{ fontWeight: 'bold', fontSize: '13px' }}>Status</TableCell>
                  <TableCell className="tableCell" style={{ fontWeight: 'bold', fontSize: '13px' }}>App Name</TableCell>
                  <TableCell className="tableCell" style={{ fontWeight: 'bold', fontSize: '13px' }}>Provider Name</TableCell>
                  <TableCell className="tableCell" style={{ fontWeight: 'bold', fontSize: '13px' }}>Phone Number</TableCell>
                  <TableCell className="tableCell" style={{ fontWeight: 'bold', fontSize: '13px' }}>Message</TableCell>
                  <TableCell className="tableCell" style={{ fontWeight: 'bold', fontSize: '13px' }}>Created At</TableCell>
                  <TableCell className="tableCell" style={{ fontWeight: 'bold', fontSize: '13px' }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? user.map(function (item, id) {
                  return (
                    <TableRow key={item.id} >
                      <TableCell className="tableCell" style={{margin:'15px'}}>
                        {statusdata(item.status)}
                      </TableCell>
                      <TableCell className="tableCell">{item.appname}</TableCell>
                      <TableCell className="tableCell">{item.providername}</TableCell>
                      <TableCell className="tableCell">{item.phonenumber}</TableCell>
                      <TableCell className="tableCell">{JSON.stringify(item.message)}</TableCell>
                      <TableCell className="tableCell">{item.createdat}</TableCell>
                      <TableCell className='tableCell'>
                        <VisibilityIcon color='string' style={{ cursor: "pointer" }} onClick={() => { openModal(true); setselecteddata(item); }} />
                     { console.log(item)}
                      </TableCell>
                    </TableRow>
                  )
                }) :
                  <Loading />
                }
              </TableBody>
            </Table>

              </div>
            <div className='pagination'>
              <Stack spacing={1}>
                <Pagination count={Math.ceil(totalData / 4)} page={page} onChange={handleChange} variant="outlined" shape="rounded" />
              </Stack>
            </div>
          </TableContainer>
          </div>
        </div>
      </div>

   
  )
}

export default UsersList



