import {baseUrl} from '../constants/BaseUrl'
import axios from "axios";

let access_token = localStorage.getItem('token');


export const listSms = async (payload) => {
    try {
        const {data} = await axios.post(`${baseUrl}/smsprovider/listSmsProvider`,payload,{
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json'
             },
        })
        return data
    } catch (error) {
        Promise.reject()
    }
}

export const addSms = async (payload) => {
    try {
        const {data} = await axios.post(`${baseUrl}/smsprovider/addSmsProvider`,payload,{
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json'
             },
        })
        return data
    } catch (error) {
        Promise.reject()
    }
}

export const editSms = async (payload) => {
    try {
        const {data} = await axios.post(`${baseUrl}/smsprovider/updateSmsProvider/`,payload,{
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json'
             },
        })
        return data
    } catch (error) {
        Promise.reject()
    }
}

export const deleteSms = async (payload) => {
    try {
        const {data} = await axios.post(`${baseUrl}/smsprovider/deleteSmsProvider`,payload,{
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json'
             },
        })
        return data
    } catch (error) {
        Promise.reject()
    }
}


