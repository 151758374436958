import {baseUrl} from '../constants/BaseUrl'
import axios from "axios";

let access_token = localStorage.getItem('token');


export const listUser = async (payload) => {
    try {
        const {data} = await axios.post(`${baseUrl}/users/listuser`,payload,{
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json'
             },
        })
        return data
    } catch (error) {
        Promise.reject()
    }
}

export const addUser = async (payload) => {
    try {
        const {data} = await axios.post(`${baseUrl}/users/adduser`,payload,{
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json'
             },
        })
        return data
    } catch (error) {
        Promise.reject()
    }
}

export const editUser = async (payload) => {
    try {
        const {data} = await axios.post(`${baseUrl}/users/updateUser`,payload,{
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json'
             },
        })
        return data
    } catch (error) {
        Promise.reject()
    }
}

export const deleteUser = async (payload) => {
    try {
        const {data} = await axios.post(`${baseUrl}/users/deleteuser`,payload,{
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json'
             },
        })
        return data
    } catch (error) {
        Promise.reject()
    }
}


