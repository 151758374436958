import './adduser.scss'
import React, { useEffect, useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import Navbar from '../navbar/Navbar'
import Sidebar from '../sidebar/Sidebar'
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { addUser } from './UserApi'
import {listSms} from '../sms/SmsApi'
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const AddUser = (props) => {

    const navigate = useNavigate();
    const [data, setData] = useState();
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [providername, setProvidername] = useState('')
    const [sender, setSender] = useState([])
    const [app, setApp] = useState([])
    const [product, setProduct] = useState([]);
    const [template, setTemplate] = useState([]);


    const OnsubmitForm = (event) => {
        event.preventDefault();
    }

    const hadelSubmit = (id) => {
        var newobj = [];
        var sender_idarray = sender.split(',');
        for (let x = 0; x < sender_idarray.length; x++) {
            var objects = {
                name: sender_idarray[x]
            };
            newobj.push(objects);
        }

    //For Template

    var temobj =[];
    var template_idarray = template.split(',');
    for (let x = 0; x <template_idarray.length; x++){
    var objects = {
    name:template_idarray[x] 
    };
    temobj.push(objects);
   }
        var data = JSON.stringify({
            "username": username,
            "password": password,
            "appname": app,
            "sender_id": newobj,
            "provider": providername,
            "template":temobj

        });

        addUser(data).then(res=>{
            if(res.status==true){
                toast.success('User Added Successfully', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                    setTimeout(() => {                       
                        navigate('/users',{replace:true})
                    }, 2000);
                }            
          })

    }
    useEffect(() => {
        
        displaysmsprovider();
     
    }, [])
    
    const displaysmsprovider=()=>{  
        var data = JSON.stringify({
          "limit": "10",
          "öffset": "0"
        });
      
        listSms(data)
          .then(response => {
            setProduct(response.smsproviders.users)
          })
          .catch(function (error) {
            console.log(error);
          });
      }

     

    return (
        <div className="add">
            <Sidebar />
            <div className="addContainer">
                <Navbar />
                <div className="userHeading">
                    <h1 className='userh'>ADD NEW USER</h1>
                    <Link to='/adduser' style={{ textDecoration: 'none' }}>
                    </Link>
                </div>
                <div className="bottom">
                    <div className="right">
                        <form onSubmit={OnsubmitForm}>
                            <div className="formInput">
                                {/* <TextField id="standard-basic" label="Provider" variant="standard" placeholder="provider" fullWidth sx={{ m: 1 }} onChange={(e)=>setProvidername(e.target.value)} /> */}
                                <div className='formInput' style={{ marginTop: '3px' }}>

                                    <FormControl variant="standard" sx={{ m: 1, minWidth: 420 }}>
                                        <InputLabel id="demo-simple-select-standard-label">Provider Name</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"                                         
                                            onChange={(e) => setProvidername(e.target.value)}                               
                                            label="Provider Name"
                                            name='dropdown'
                                        >
                                          
                                            {product.map(function (item, id) {
                                   return (
                                <MenuItem  value={item.id}><span>{item.providername}</span></MenuItem>
                                            
                                )})}
                                          </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="formInput">
                                <TextField id="standard-basic" label="UserName" variant="standard" placeholder="username" fullWidth sx={{ m: 1 }} onChange={(e) => setUsername(e.target.value)} />
                            </div>
                            <div className="formInput">
                                <TextField id="standard-basic" label="Password" variant="standard" placeholder="password" fullWidth sx={{ m: 1 }} onChange={(e) => setPassword(e.target.value)} />
                            </div>

                            <div className="formInput">
                                <TextField id="standard-basic" label="AppName" variant="standard" placeholder="appname" fullWidth sx={{ m: 1 }} onChange={(e) => setApp(e.target.value)} />
                            </div>
                            <div className="formInput">
                            <label>Sender ID</label>
                                <TextareaAutosize id="standard-basic" label="Sender_ID" variant="standard"  fullWidth sx={{ m: 1 }} onChange={(e) => setSender(e.target.value)}
                                
                                aria-label="minimum height"
                                minRows={2}
                                style={{ width: 425 }}
                                />
                                <small style={{marginLeft:'10px'}}>For Multiple Value Use Comma ( , )</small>

                            </div>
                            <div className="formInput">
                                <label>Template</label>
                                <TextareaAutosize id="standard-basic" label="Template" variant="standard"  fullWidth sx={{ m: 1 }} onChange={(e) => setTemplate(e.target.value)} 
                                aria-label="minimum height"
                                minRows={2}
                                style={{ width: 425 }}
                                />
                                <small style={{marginLeft:'10px'}}>For Multiple Value Use Comma ( , )</small>

                            </div>
                            <div className="formInput">
                                <button style={{ marginLeft: '8rem' }} onClick={() => {hadelSubmit()}} >Submit</button>
                                <ToastContainer/>
                            </div>
                            
                        </form>
                    </div>
                </div>

            </div>




        </div>

    )
}

export default AddUser