import "./dashboard.scss";
import React,{ useEffect, useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MailLockIcon from '@mui/icons-material/MailLock';
import { Link } from "react-router-dom";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { dashboardData } from "./DashboardApi";
import {baseUrl} from '../constants/BaseUrl'


const Dashboard = ({ type }) => {
  
  
  const [apidata, setApidata] = useState([]);
  const [apicall, setApicall] = useState(true);
  let data;
  const dashboarddata = () => {
    if(apicall){
  dashboardData()
  .then(res => {setApidata(res.data)
  setApicall(false);
  })

  .catch(function (error) {
    console.log(error);
  });
}
}
  useEffect(() => {
    dashboarddata();
  }, [])
// useEffect(() => {

//   dashboardData()
//   .then(res => {setApidata(res.data)})

//   .catch(function (error) {
//     console.log(error);
//   });
//   //dashboardData();
// })

 
  switch (type) {
    case "user":
      data = {
        title: "SEND-SMS",
        isMoney: false,
        value:apidata.sendmessagecount,
        link: <Link to='/report' style={{textDecoration:'none'}}>View All Sms</Link>,
        icon: (
          <ForwardToInboxIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "order":
      data = {
        title: "SMS PROVIDER",
        isMoney: false,
        value:apidata.smsprovidersCount,
        link: <Link to='/sms'  style={{textDecoration:'none'}}>View All Sms Provider</Link>,
        icon: (
          <MailLockIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    case "earning":
      data = {
        title: "USER",
        isMoney: true,
        value:apidata.usersCount,
        link: <Link to='/users'  style={{textDecoration:'none'}}>View All Users</Link>,     
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
          />
        ),
      };
      break;
    default:
      break;
  }


  return (
    <div className="widget">
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">
            {data.value}
        </span>
        <span className="link">{data.link}</span>
      </div>
      <div className="right">
        <div className="percentage positive">
          <KeyboardArrowUpIcon />
        </div>
        {data.icon}
      </div>
    </div>
  );
};

export default Dashboard;
