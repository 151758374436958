import React from "react";
import Dashboard from "../dashboard/Dashboard";
import Navbar from "../navbar/Navbar";
import Sidebar from "../sidebar/Sidebar"; 
import "./home.scss";
// import Widget from "../../components/widget/Widget";

const Home = () => {
  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
          <Navbar />
          <div className="dashboard">

          <Dashboard type="user" />
          <Dashboard type="order" />
          <Dashboard type="earning" /> 
          </div>
          </div>
    </div>
  );
};


export default Home