import * as React from 'react';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import axios from 'axios';
import { baseUrl } from '../../constants/BaseUrl';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function SmsDeleteModal(props) {
  const [open, setOpen] = React.useState(false);
  const [user, setUser] = React.useState([]);
  const [data,setData] = React.useState([])
  const [refresh,setRefresh] = React.useState(false);
  const [loading, setLoading] = useState(false)
  const [page,setPage] = useState(1);
  const [pagedata, setPageData] = useState([]);
    
  var access_token = localStorage.getItem('token'); 
  const displaysmsprovider = () => { 
    let data = {
      limit :4,
      offset:page==1?0:(page-1)*4
    }  
    axios.post(baseUrl + "/smsprovider/listSmsProvider",data,{
      headers: {
        'Authorization': 'Bearer ' + access_token,
        'Content-Type': 'application/json'
      }
    }).then(res=>{
      let {data : {smsproviders : {smsprovidersCount,users = []} }} = res || {}
      setLoading(true)
      setData(smsprovidersCount)
      setPageData(users)
    })
  }
 
  const deleteOperation = async (id) => {

    var access_token = localStorage.getItem('token');

    var axios = require('axios');
    var data = JSON.stringify({
      "id": id,
    });

    var config = {
      method: 'post',
      url: baseUrl + `/smsprovider/deleteSmsProvider`,

      headers: {
        'Authorization': 'Bearer ' + access_token,
        'Content-Type': 'application/json'

      },
      data: data
    };
     
    axios(config)
      .then(function (res) {
         console.log('res',res)
     
        if (res.status == 200) {
          toast.success('Sms Deleted Successfully', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
            setTimeout(() => {
            setRefresh(true)
            setOpen(false)         
            setUser(res.data)
            props && props.onUpdate(res || "successful")
            setData(props.selectdata)
            setOpen(false); 
          }, 2000);
        } else {
         setData(props.selectdata)
        }

      })
      .catch(function (error) {
        console.log(error);
        setUser()
       

      });
  }
 
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  

  return (
    <div>
        {
        onclose ? (
        <RestoreFromTrashIcon color="error" onClick={handleClickOpen}/>
        ) : null }
      <Dialog
        open={props.visility}
        onClose={props.closemodal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        </DialogTitle>
        <div style={{height:'200px',width:'400px',border:'50%'}}>
        <div style={{borderRadius:'50%',height:'60px',width:'80px'}}>
        <span className="bi bi-trash-fill" style={{color:'red',fontSize:'40px',marginLeft:'11rem',borderRadius:'50% solid red'}}></span>
        </div>
        <div style={{marginLeft:'30px'}}>
          <h5><b>You are about to delete a Provider?</b></h5>
        </div>
        <div style={{marginLeft:'2rem',marginTop:'30px',fontSize:'15px',}}>
          <p>This will delete the provider from Sms Provider </p>
           <span style={{marginLeft:'7rem'}}> Are you Sure ?</span>
         
        </div>
          </div>
        <DialogActions>
           <button type="button" className="btn btn-secondary" onClick={props.closemodal} style={{backgroundColor:'gray',width:'100px',height:'39px'}}>Cancel</button>
              <button type="button" className="btn btn-secondary"  style={{backgroundColor:'red',width:'100px',height:'39px'}} onClick={()=>{deleteOperation(props.selectdata)}}>Delete</button>
              <ToastContainer/>
        </DialogActions>
      </Dialog>
    </div>
  );
}
