import {baseUrl} from '../constants/BaseUrl'
import axios from "axios";

let access_token = localStorage.getItem('token');


export const dashboardData = async () => {
    try {
        const {data} = await axios.get(`${baseUrl}/dashboard/dashboarddata`,{
            headers: {
              'Authorization': 'Bearer '+access_token
             },
        })
        return data
    } catch (error) {
        Promise.reject()
    }
}