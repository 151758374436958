import './Reportsmodal.scss'
import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Divider } from '@mui/material';



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: -2,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon onClick={props.closemodal} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};
export default function CustomizedDialogs(props) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const statusdata = (status) => {
    if (status == 'Sent') {
      return (
        <p style={{ color: 'grey', fontWeight: 'bold' }}>Sent</p>
      )
    }
    if (status == 'DELIVERED') {
      return (
        <p  style={{ color: 'green', fontWeight: 'bold'}}>Delivered</p>
      )
    }
    if (status == 'Failed' || status == 'REJECTD') {
      return (
        <p style={{ color: 'red', fontWeight: 'bold' }}>{status}</p>
      )
    }
  }
  return (
    <div>
      <BootstrapDialog
        onClose={props.closemodal}
        aria-labelledby="customized-dialog-title"
        open={props.visility}
      >
        <Divider />
        <BootstrapDialogTitle id="customized-dialog-title"
          onClose={props.closemodal}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
        </BootstrapDialogTitle>
        <Divider />
        <div className='main' style={{ height: '60vh', width: '90vh' }}>
          <div>
            <h6>Status :</h6>
            <h6>App Name :</h6>
            <h6>Provider Name :</h6>
            <h6>Country Code :</h6>
            <h6>Phone Number :</h6>
            <h6>Sender ID :</h6>
            <h6>Message Type :</h6>
            <h6>ID :</h6>
            <h6>Message ID :</h6>
            <h6>Created At :</h6>
            <h6>Sent At :</h6>
            <h6>Deliver At :</h6>  
          </div>

          <div>
            <h6> {statusdata(props.data.status)}</h6>
            <h6  style={{marginTop:'-9px'}}> {props.data.appname == undefined ? "Null" : props.data.appname}</h6>
            <h6> {props.data.providername == undefined ? "Null" : props.data.providername} </h6>
            <h6> {props.data.countrycode == undefined ? "Null" : props.data.countrycode} </h6>
            <h6> {props.data.phonenumber == undefined ? "Null" : props.data.phonenumber} </h6>
            <h6> {props.data.sender_id == undefined ? "Null" : props.data.sender_id} </h6>
            <h6> {props.data.messagetype == undefined ? "Null" : props.data.messagetype} </h6>
            <h6> {props.data.id == undefined ? "Null" : props.data.id}</h6>
            <h6> {props.data.message_id == undefined ? "Null" : props.data.message_id} </h6>
            <h6> {props.data.createdat == undefined ? "Null" : props.data.createdat} </h6>
            <h6> {props.data.sentat == undefined ? "Null" : props.data.sentat} </h6>
            <h6> {props.data.deliveredat == undefined ? "Null" : props.data.deliveredat} </h6>
          </div>
        </div>
      </BootstrapDialog>
    </div>
  );
}
