import './login.scss'
import React, { useEffect, useState } from 'react'
import { Grid, Paper, TextField, Button } from '@mui/material'
import { FormControlLabel } from '@mui/material';
import { Checkbox } from '@mui/material';
import { useNavigate } from 'react-router-dom'
import {loginSuperadmin} from './LoginApi';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




const Login = () => {

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();



 

    const  login = () => {
       const payload = {username,password};
       console.log('payload',payload)
       loginSuperadmin(payload).then(res => {
             console.log('pay',res);
             if (res.status){
                toast.success(res.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                    setTimeout(() => {
                        setLoading(false)
                        localStorage.setItem('token',res.access_token);
                        navigate('home', { replace: true })
                     }, 2000);
             }else{
                toast.error(res.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                navigate('/')
                setLoading(true)
            }
         }).catch(err=>('error'+err.response))  
        }
       
       
    //     await loginSuperadmin(data).then(response =>{
    //         var data = {};
    //         data.username=username;
    //         data.password=password
    //         console.log('response',response.data);
    //     }) 

    //  }

   
    

    const handelChange = (e) => {
        setUsername(e.target.value)

    }

    const handleKeyPress = (event) => {
        if (event.keyCode === 13 || event.which === 13) {
            login()
        }
    }
    const paperStyle = { padding: 20, height: '63vh', width: 400, margin: "0 auto", marginTop: '50px', borderRadius: '30px' }
    const btnstyle = {  marginLeft: '118px', backgroundColor: '#294e9f' ,marginTop:'10px'}


    return (
        <>
        
            <Grid>
                <Paper style={paperStyle}>
                    <Grid align='center'>
                        <img src='https://whatsapp.inaipiapp.com/imperiumapp/assets/img/logo.png' alt='' style={{ height: '100px', width: '80px', objectFit: 'content', }} />

                    </Grid>
                    <TextField label='Username' placeholder='Enter username' fullWidth required style={{ marginTop: '60px' }} onChange={handelChange} onKeyPress={handleKeyPress} />
                    <TextField label='Password' placeholder='Enter password' type='password' fullWidth required style={{ marginTop: '15px' }} onChange={(e) => setPassword(e.target.value)} onKeyPress={handleKeyPress} />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label="Remember me"
                    />
                  {/* <Link to='/home'> */}
                  <Button className='loginBtn' type='submit' variant="contained" style={btnstyle} onClick={login}>Sign in</Button>
                  <ToastContainer />
                  {/* </Link>   */}
                </Paper>
            </Grid>
            {/* <ToastContainer /> */}
        </>
    )
}

export default Login

