import './smslist.scss'
import React, { useState } from 'react'
import Sidebar from '../sidebar/Sidebar'
import Navbar from '../navbar/Navbar'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Loading from "../loading/Loading";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import SmsDeleteModal from "../action/smsmodal/SmsDeleteModal";
import VisibilityIcon from '@mui/icons-material/Visibility';
import SmsDetails from "../action/smsmodal/SmsDetailsModal";
import { listSms,deleteSms } from './SmsApi';
import {ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Smslist = () => {


    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [pagedata, setPageData] = useState([]);
    const [page,setPage] = useState(1);
    const [totalData, setTotalData] = useState(null)
    const [modal, openModal] = useState(false)
    const [selectedata, setselecteddata] = useState('');
    const [newmodal, setNewmodal] = useState(false)

    useEffect(() => {
      displaysmsprovider();
    }, [page])
    const displaysmsprovider = () => {
    
      let data = {
        limit :4,
        offset:page==1?0:(page-1)*4
      }
     

      listSms(data).then(res=>{
        console.log("first",res)
        let  {smsproviders : {smsprovidersCount,users = []} } = res || {}
        setLoading(true)
        setTotalData(smsprovidersCount)
        setPageData(users)
      })
       

    }
  
  
    const deleteOperation = async (id) => {
     
      var data = {  id: id };
      deleteSms(data).then(res=>{
        if (res.status !== true){
          displaysmsprovider();
         
        } else {
        
        }
      }).catch(err=>console.log("error"))
       
    }      
    const handleChange = (event, value) => {
        setPage(value);
      };
  
      const closemodal = () => {
        openModal(false)
      }
      
     const closeModal = () =>{
       setNewmodal(false)
     }
  
     const refreshPage = value =>{
      if(value?.data?.status){
        displaysmsprovider()
        closemodal()
      }
    }

  return (
  
    <div className="home">
    <Sidebar />
    <SmsDeleteModal visility={modal} 
        selectdata={selectedata} 
        closemodal={event => closemodal(event)} 
        deleteOperation={()=>deleteOperation}
        displaysmsprovider={()=>displaysmsprovider}
        onUpdate={value => refreshPage(value)} 
        />

          <SmsDetails visility={newmodal}
          data={selectedata}
          closeModal={event =>closeModal(event)}
            /> 
    

    <div className="homeContainer">
      <Navbar  />
      <div className="top" style={{backgroundColor:'#294e9f'}}>
       <h2>SMS PROVIDER</h2>
       <Link to='/addSms' style={{textDecoration:'none'}}>
       <Button variant="outlined" startIcon={<AddCircleOutlineIcon />} style={{backgroundColor:'aliceblue',color:'black'}}>Add SMS Provider</Button>
       </Link>
      </div>   
      <div className="bottom">
        <div className="right"> 
        <TableContainer component={Paper} className="table" style={{width:'100vw'}}
      sx={{
        height: 400
      }} >


       <div style={{minHeight:'300px'}}>
      <Table sx={{ minWidth: 500}} aria-label="simple table" style={{ fontSize: '10px' }}>
        <TableHead style={{ fontweight: 'bold' }}>
          <TableRow >
            <TableCell className="tableCell" style={{ fontWeight: 'bold', fontSize: '12px' }}>Username</TableCell>
            <TableCell className="tableCell" style={{ fontWeight: 'bold', fontSize: '12px' }}>Password</TableCell>
            <TableCell className="tableCell" style={{ fontWeight: 'bold', fontSize: '12px' }}>Api key</TableCell>
            <TableCell className="tableCell" style={{ fontWeight: 'bold', fontSize: '12px' }}>Api url</TableCell>
            {/* <TableCell className="tableCell" style={{ fontWeight: 'bold', fontSize: '12px' }}>SenderID</TableCell> */}
            <TableCell className="tableCell" style={{ fontWeight: 'bold', fontSize: '12px' }}>Provider Name</TableCell>
            <TableCell className="tableCell" style={{ fontWeight: 'bold', fontSize: '12px' }}>Provider Icon</TableCell>
           {/* <TableCell className="tableCell" style={{ fontWeight: 'bold', fontSize: '12px' ,width:'5vw' }}>Template</TableCell> */}
            <TableCell className="tableCell" style={{ fontWeight: 'bold', fontSize: '12px',width:'7vw' }}>Action</TableCell>
            <TableCell className="tableCell" style={{ fontWeight: 'bold', fontSize: '12px' }}>View</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? pagedata.map(function (item, id) {
            return (
              <TableRow key={item.id} >
                <TableCell className="tableCell">{item.username}</TableCell>
                <TableCell className="tableCell">{item.password}</TableCell>
                <TableCell className="tableCell">{item.api_key}</TableCell>
                <TableCell className="tableCell">{item.api_url}</TableCell>
                <TableCell className="tableCell">
                    {item.providername}
                </TableCell>
                <TableCell className="tableCell" style={{width:'10px'}} >
                  <div className="cellWrapper" style={{width:'10px'}} >
                    <img src={item.providericon} alt="" className="image" />
                  </div>
                </TableCell>
               
                <TableCell className='tableCell' >
                  <span class='bi bi-pencil-fill' style={{fontSize:'15px',marginLeft:'-3px',color:'gray',marginTop:'15px', width:'30px'}} onClick={() => navigate(`/editSms/${item.id}`, { state: { data: item } })}></span>
                  <Tooltip title='Delete'>
                    <span className="bi bi-trash-fill" style={{fontSize:'17px',marginLeft:'10px',color:'red',width:'30px'}} onClick={()=>{openModal(true);setselecteddata(item.id);}}></span>
                  </Tooltip>
                </TableCell>

                <TableCell className='tableCell'>
                        <VisibilityIcon color='string' style={{ cursor: "pointer" }} onClick={() => { setNewmodal(true); setselecteddata(item);}}/>
                      </TableCell>

              </TableRow>
            )
          }) : <Loading />}
        </TableBody>
      </Table>

</div>
          <div className="footer">

          <Stack spacing={2}>
          <Pagination count={Math.ceil(totalData/4)} page={page} onChange={handleChange} variant="outlined" shape="rounded"/>
          </Stack>
          </div>
     <div>
     </div>
    </TableContainer>
          </div>
          </div>     
    </div>
  </div>
   
  )
}

export default Smslist