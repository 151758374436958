import React from "react";
import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MailLockIcon from '@mui/icons-material/MailLock';
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Link } from "react-router-dom";
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import inaipi from '../image/inaipi.png.png'

const Sidebar = () => {
  
  return (
    <div className="sidebar">
      <div className="top">

        {/* <span className="logo">Inaipi</span> */}
        <img className="logo" src={inaipi} />

      </div>
      <hr />
      <div className="center" style={{width:'153px'}} >
        <ul className="nav-ul">

          <Link to='/home' style={{ textDecoration: "none" }}>
            <li className="nav-li">
              <DashboardIcon className="icon" />
              <span className="sidebar-span">Dashboard</span>
            </li>

          </Link>
          <Link to="/sms" style={{ textDecoration: "none" }}>
            <li className="nav-li">
              <MailLockIcon className="icon" />
              <span className="sidebar-span">SMS Provider</span>
            </li>
          </Link>

          <Link to="/users" style={{ textDecoration: "none" }}>
            <li className="nav-li">
              <GroupOutlinedIcon className="icon" />
              <span className="sidebar-span">Users</span>
            </li>
          </Link>
          <Link to="/report" style={{ textDecoration: "none" }}>
          <li className="nav-li">
            <AutoGraphIcon className="icon" />
            <span className="sidebar-span">Reports</span>
          </li>
          
          </Link>

          <Link to='/' style={{ textDecoration: "none" }}>
            <li className="nav-li">
              <ExitToAppIcon className="icon" />
              <span className="sidebar-span">Logout</span>
            </li>

          </Link>
        </ul>
      </div>
      
    </div>
  );
};

export default Sidebar;
