import React from "react";
import Home from "./components/home/Home";
import Login from "./components/login/Login";
import UserList from "./components/users/UserList";
import Adduser from "./components/users/Adduser";
import Edituser from "./components/users/Edituser";
import Smslist from "./components/sms/Smslist";
import Addsms from "./components/sms/Addsms";
import Editsms from "./components/sms/Editsms";
import Reports from "./components/reports/Reports"






import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
 
   <div>
   <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Login />} />
            <Route path="home" element={<Home />} />
            <Route path='users' index element={<UserList />} />
            <Route path='adduser' element={<Adduser />} />
            <Route path='edituser/:userId' element={<Edituser />} />
            <Route path="sms" index element={<Smslist />} />
            <Route path='addSms' element={<Addsms />} />
            <Route path='editSms/:smsId' element={<Editsms />} />
            <Route path="report" element={<Reports />} />
            </Route>
            </Routes>
            </BrowserRouter>
  </div>

  );
}

export default App;
