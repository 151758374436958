import {baseUrl} from '../constants/BaseUrl'
import axios from "axios";

let access_token = localStorage.getItem('token');
export const loginSuperadmin = async ({username,password}) => {
    const payload = {
        username:username,
        password:password
    }
    try {
        const {data} = await axios.post(`${baseUrl}/users/login`,payload,{
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json'
             },
        })
        return data
    } catch (error) {
        Promise.reject()
    }
}

