import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import axios from 'axios';
import { baseUrl } from '../../constants/BaseUrl';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




export default function UserDeleteModal(props) {
  const [open, setOpen] = React.useState(true);
  const [user, setUser] = React.useState([]);
  const [data,setData] = React.useState([])
  const [refresh,setRefresh] = React.useState(false);
  const [loading, setLoading] = useState(false)
  const [page,setPage] = useState(1);

  const navigate = useNavigate(); 

  const deleteOperation = (id) => {
    var access_token = localStorage.getItem('token');

    var axios = require('axios');
    var data = JSON.stringify({
      "id": id,

    });

    var config = {
      method: 'post',
      url: baseUrl + `/users/deleteuser`,

      headers: {
        'Authorization': 'Bearer ' + access_token,
        'Content-Type': 'application/json'

      },
      data: data
    };
     
    axios(config)
      .then(function (res) {
        if (res.status == 200) {
          toast.success('User Deleted Successfully', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
            setTimeout(() => {     
              setRefresh(true)
              setOpen(false)  
             setUser(res.data)
             props && props.onUpdate(res || "successful")   
            setData(props.selectdata)
            setOpen(false); 
            }, 2000);
          
        } else {
          setData(props.selectdata)
        }

      })
      .catch(function (error) {
        setUser()
       

      });
   }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div> 
      {
         onclose ? (

        <RestoreFromTrashIcon color="error" onClick={handleClickOpen}/>
       
       ) : null }
      <Dialog
        open={props.visility}
        onClose={props.closemodal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"
        onClose = {props.closemodal}
        open ={open}
        >
          <div style={{height:'200px',width:'400px',border:'50%'}}>
        <div style={{borderRadius:'50%',height:'60px',width:'80px'}}>
        <span className="bi bi-trash-fill" style={{color:'red',fontSize:'40px',marginLeft:'11rem',borderRadius:'50% solid red'}}></span>
        </div>
        <div style={{marginLeft:'23px'}}>
          <h4><b>You are about to delete a user ?</b></h4>
        </div>
        <div style={{marginLeft:'4rem',marginTop:'30px',fontSize:'15px',}}>
          <p>This will delete the user from userlist </p>
           <span style={{marginLeft:'5rem'}}> Are you Sure ?</span>
         
        </div>
          </div>
        </DialogTitle>
        <DialogContentText>
          
          
        </DialogContentText>
        <DialogActions>
              <button type="button" className="btn btn-secondary" onClick={props.closemodal} style={{backgroundColor:'gray',width:'100px',height:'39px'}}>Cancel</button>
              <button type="button" className="btn btn-secondary"  onClick={()=>{deleteOperation(props.selectdata); }}  style={{backgroundColor:'red',width:'100px',height:'39px'}}>Delete</button>
              <ToastContainer />
        </DialogActions>
      </Dialog>
    </div>
  );
}
